import React from 'react';

const Features = () => {
    const features = [
        'Expert Instructors',
        'Hands-on Learning',
        'Cutting-edge Curriculum',
        'Small Class Sizes',
        'Flexible Schedule',
        'Real-world Applications',
    ];

    return (
        <section className="py-12 sm:py-16 bg-indigo-600 text-white">
            <div className="container mx-auto px-4 text-center">
                <h2 className="text-2xl sm:text-3xl font-bold mb-6 sm:mb-8">Why Choose RoboMindLabs?</h2>
                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4 sm:gap-6">
                    {features.map((feature) => (
                        <div key={feature} className="bg-indigo-700 rounded-lg p-4 sm:p-6">
                            <h3 className="text-lg sm:text-xl font-semibold">{feature}</h3>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Features;