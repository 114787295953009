import React from 'react';

const Header = () => {
    return (
        <header className="bg-gray-900 shadow-lg">
            <div className="container mx-auto px-4 py-4 sm:py-6">
                <div className="flex items-center justify-between">
                    <a href="/" className="flex items-center">
                        <div className="w-20 h-14 sm:w-28 sm:h-20 relative">
                            <img
                                src="https://hebbkx1anhila5yf.public.blob.vercel-storage.com/0AE30C57-951C-40BE-99BD-23E775E1A483_2-removebg%20(1)-818IFsDRqE1Xtwq3Wj6yPlOf0DFPyK.png"
                                alt="Robo Mind Labs Logo - A colorful brain made of interconnected nodes"
                                className="w-full h-full object-contain"
                            />
                        </div>
                        <div className="flex flex-col ml-2 sm:ml-3">
                            <span className="text-xl sm:text-2xl lg:text-3xl font-bold text-white leading-tight">ROBOMIND LABS</span>
                            <span className="text-xs sm:text-sm text-gray-400 leading-tight whitespace-nowrap">WHERE LEARNING MEETS INNOVATION</span>
                        </div>
                    </a>
                    <nav className="hidden sm:flex items-center space-x-6">
                        <a href="#courses" className="text-base sm:text-lg text-gray-300 hover:text-white transition-colors py-2">
                            Courses
                        </a>
                        <a href="#workshops" className="text-base sm:text-lg text-gray-300 hover:text-white transition-colors py-2">
                            Workshops
                        </a>
                        <a href="#about" className="text-base sm:text-lg text-gray-300 hover:text-white transition-colors py-2">
                            About
                        </a>
                        <a href="#contact" className="text-base sm:text-lg text-gray-300 hover:text-white transition-colors py-2">
                            Contact
                        </a>
                        <button className="bg-indigo-600 hover:bg-indigo-700 text-white text-base sm:text-lg px-6 py-3 rounded transition-colors duration-300">
                            Get Started
                        </button>
                    </nav>
                    <button className="sm:hidden text-white">
                        <svg xmlns="http://www.w3.org/2000/svg" className="h-8 w-8" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
                        </svg>
                    </button>
                </div>
            </div>
        </header>
    );
};

export default Header;