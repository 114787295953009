import React from 'react';

const Hero = () => {
    return (
        <section className="py-12 sm:py-20 px-4">
            <div className="container mx-auto max-w-4xl text-center">
                <h1 className="text-3xl sm:text-4xl md:text-5xl lg:text-6xl font-bold text-gray-900 mb-4 sm:mb-6">
                    Unleash Your Child's Potential in Robotics
                </h1>
                <p className="text-base sm:text-lg md:text-xl text-gray-600 mb-6 sm:mb-8">
                    Empower the next generation of innovators with fun and exciting robotics education
                </p>
                <div className="flex flex-col sm:flex-row justify-center gap-4">
                    <button className="bg-indigo-600 hover:bg-indigo-700 text-white text-base sm:text-lg px-6 sm:px-8 py-2 sm:py-3 w-full sm:w-auto rounded">
                        Explore Courses
                    </button>
                    <button className="text-indigo-600 border border-indigo-600 hover:bg-indigo-50 text-base sm:text-lg px-6 sm:px-8 py-2 sm:py-3 w-full sm:w-auto rounded">
                        Book a Demo
                    </button>
                </div>
            </div>
        </section>
    );
};

export default Hero;