import React from 'react';

const CallToAction = () => {
    return (
        <section className="py-12 sm:py-16 bg-white">
            <div className="container mx-auto px-4 max-w-3xl text-center">
                <h2 className="text-2xl sm:text-3xl font-bold text-gray-900 mb-4 sm:mb-6">Ready to Start Your Robotics Journey?</h2>
                <p className="text-base sm:text-lg md:text-xl text-gray-600 mb-6 sm:mb-8">
                    Join RoboMindLabs today and give your child the skills they need for the future.
                </p>
                <button className="bg-indigo-600 hover:bg-indigo-700 text-white text-base sm:text-lg px-6 sm:px-8 py-2 sm:py-3 w-full sm:w-auto rounded">
                    Enroll Now
                </button>
            </div>
        </section>
    );
};

export default CallToAction;