import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import Programs from './components/Programs';
import Features from './components/Features';
import CallToAction from './components/CallToAction';
import Footer from './components/Footer';

function App() {
    return (
        <div className="flex flex-col min-h-screen bg-gradient-to-br from-purple-50 to-indigo-100">
            <Header />
            <main className="flex-grow">
                <Hero />
                <Programs />
                <Features />
                <CallToAction />
            </main>
            <Footer />
        </div>
    );
}

export default App;