import React from 'react';
import { Bot, Cpu, Zap } from 'lucide-react';

const ProgramCard = ({ title, description, Icon }) => (
    <div className="bg-gray-50 rounded-lg p-6 shadow-md hover:shadow-lg transition-shadow">
        <Icon className="w-12 h-12 text-indigo-600 mb-4" />
        <h3 className="text-xl font-semibold mb-2">{title}</h3>
        <p className="text-gray-600 mb-4">{description}</p>
        <a href="#" className="text-indigo-600 hover:text-indigo-700 flex items-center">
            Learn More
            <svg className="w-4 h-4 ml-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9 5l7 7-7 7" />
            </svg>
        </a>
    </div>
);

const Programs = () => {
    const programs = [
        { title: 'Robotics Fundamentals', description: 'Learn the basics of robotics and programming', Icon: Bot },
        { title: 'AI & Machine Learning', description: 'Explore the world of artificial intelligence', Icon: Cpu },
        { title: 'Advanced Robotics Projects', description: 'Build complex robots and solve real-world problems', Icon: Zap },
    ];

    return (
        <section id="courses" className="py-12 sm:py-16 bg-white">
            <div className="container mx-auto px-4">
                <h2 className="text-2xl sm:text-3xl font-bold text-center text-gray-900 mb-8 sm:mb-12">Our Programs</h2>
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6 sm:gap-8">
                    {programs.map((program) => (
                        <ProgramCard key={program.title} {...program} />
                    ))}
                </div>
            </div>
        </section>
    );
};

export default Programs;